import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    hotelList: [],
    officialChannelList: [],
    levelList: [], // 会员折扣配置选择的级联数据
    colorMap: {}, //存储标签颜色
  },
  mutations: {
    SET_HOTEL_LIST(state, hotels) {
      state.hotelList = hotels;
    },
    SET_LEVEL_LIST(state, levelList) {
      state.levelList = levelList;
    },
    // 设置官网分销渠道列表
    SET_OFFICIAL_CHANNEL_LIST(state, channels) {
      state.officialChannelList = channels;
    },
    // 记录会员名称标签颜色
    SET_COLOR_MAP(state, colorMap) {
      state.colorMap = colorMap;
    },
  },
  actions: {
    async fetchHotelList({ commit }) {      
      const res = await $httpUtilCp.fetch({ 
        url: 'getValidHotelList',
        data: { status: 1 }
      });
      let hotels = [];
      if (res.retcode === 0 && Array.isArray(res.body)) {
        hotels = (res.body || []).map(item => ({ ...item, id: item.hotelId, name: item.hotelName }));
      }
      commit('SET_HOTEL_LIST', hotels);
    },
    // 获取官网分销渠道列表
    async fetchOfficialChannelList({ commit }) {
      const res = await $httpUtilCp.fetch({
        url: 'getCrsChannelList',
        data: { statusFlg: 1 },
      });
      if (res.retcode === 0) {
        const channels = res.body.filter(item => item.memberRateDistributionFlag === 1);
        commit('SET_OFFICIAL_CHANNEL_LIST', channels);
      }
    },
  },
  plugins: [    
    createPersistedState(),  
  ],
});
